import { NewCommentForm } from '../../../../../components/comments';
import { usePost } from '../../PostContext';

interface StickyCommentProps {
  anchor: HTMLDivElement | null;
}

const StickyNewComment = ({ anchor }: StickyCommentProps) => {
  const scrollToNewComment = () => {
    anchor?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
  };
  const { slug, id } = usePost();

  return (
    <div className="sticky bottom-0 z-sticky-content mt-6 bg-cream pb-5.5">
      <NewCommentForm className="pt-3.5" onComplete={scrollToNewComment} slug={slug} postId={id} />
    </div>
  );
};

export { StickyNewComment };
