import { Author } from '../../types';
import { ROUTES } from '../../../../lib/ROUTES';
import { Button, ButtonColor, ButtonVariant } from '../../../../components/Button';
import { getDisplayedName } from '../../../../lib/getDisplayedName';
import { tw } from '../../../../styles/tw';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { hasPermission } from '../../../../lib/apollo';
import { UserPermission } from '../../../../lib/graphql.document';

interface Props {
  authors: Author[];
}

const Authors = ({ authors }: Props): JSX.Element | null => {
  const { data: loginData } = useLoginInfo();

  const showBanned = hasPermission(loginData?.loginInfo.user?.permissions ?? [], UserPermission.Moderate);

  if (!authors.length) {
    return null;
  }

  return (
    <p className="text-blood">
      {authors.length === 1 ? 'Автор: ' : 'Авторы: '}
      {authors.map(({ nickname, id, alias, banned }, idx) => [
        idx === 0 ? '' : ', ',
        <Button
          className={tw('inline-block', banned && showBanned && 'line-through')}
          color={ButtonColor.Primary}
          variant={ButtonVariant.Underlined}
          key={id}
          to={ROUTES.USER_FILTER.buildPath({ id: alias || id })}
        >
          {getDisplayedName(nickname, alias)}
        </Button>,
      ])}
    </p>
  );
};

export { Authors };
