import { useMatch } from 'react-router';
import { ROUTES } from '../../lib/ROUTES';
import { Badge } from '../Badge';

interface ModerationBadgeProps {
  isOnModeration: boolean;
  className?: string;
}

const ModerationBadge = ({ isOnModeration, className }: ModerationBadgeProps) => {
  const isOnBlogsPage = Boolean(useMatch(ROUTES.BLOGS));

  if (!isOnModeration) {
    return null;
  }

  return isOnBlogsPage ? (
    <Badge round className={className}>
      М
    </Badge>
  ) : (
    <Badge className={className}>На модерации</Badge>
  );
};

export { ModerationBadge };
