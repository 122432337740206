import { useLocation } from 'react-router-dom';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { Button, ButtonVariant } from '../../../../components/Button';
import { useActionsButtons } from './useActionsButtons';
import { ROUTES } from '../../../../lib/ROUTES';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { useOpenModerationPopup } from '../../../../components/ModerationPopupProvider';

interface AuthorButtonsProps {
  isPublished: boolean;
  isOnModeration: boolean;
  postId: string;
}

const AuthorButtons = ({ isPublished, isOnModeration, postId }: AuthorButtonsProps) => {
  const {
    onDelete,
    onUnpublish,
    onPublish,
    onRequestModeration,
    loading,
    publishing,
    deleting,
    unpublishing,
    requestingModeration,
  } = useActionsButtons(postId);

  const location = useLocation();

  const { user } = useLoginInfo();

  assertIsDefined(user, 'Publish button should not be rendered if there is no user');

  const openModerationPopup = useOpenModerationPopup();

  const onTogglePublish = async () => {
    if (isPublished) {
      void onUnpublish();
    } else if (user.moderationRequired) {
      await onRequestModeration();
      openModerationPopup();
    } else {
      void onPublish();
    }
  };

  return (
    <div className="mt-6 flex w-full flex-col gap-1.5 lg:flex-row lg:flex-wrap">
      <Button
        variant={ButtonVariant.Outlined}
        to={ROUTES.BLOG_EDIT.buildPath({ id: postId }, { backTo: `${location.pathname}${location.search}` })}
      >
        Редактировать
      </Button>
      <Button
        variant={ButtonVariant.Outlined}
        disabled={loading || (isOnModeration && user.moderationRequired)}
        onClick={onTogglePublish}
      >
        {publishing
          ? 'Публикуем...'
          : unpublishing
          ? 'Снимаем с публикации...'
          : requestingModeration
          ? 'Отправляем на модерацию...'
          : isPublished
          ? 'Снять с публикации'
          : 'Опубликовать'}
      </Button>
      <Button variant={ButtonVariant.Outlined} disabled={loading} onClick={onDelete}>
        {deleting ? 'Удаляем...' : 'Удалить'}
      </Button>
    </div>
  );
};

export { AuthorButtons };
