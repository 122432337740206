import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { getDefined } from '@flame-frontend-utils/commons';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from '../../../../../../lib/ROUTES';

interface ChildrenOpenStateProviderProps {
  rootCommentId: string;
  children?: ReactNode;
}

const ChildrenOpenStateContext = createContext<boolean | undefined>(undefined);
const ToggleChildrenContext = createContext<((nextValue?: boolean) => void) | undefined>(undefined);

const ChildrenOpenStateProvider = ({ children, rootCommentId }: ChildrenOpenStateProviderProps) => {
  const [{ parentCommentId: currentParentCommentId }] = useTypedSearchParams(ROUTES.POST);
  const [isOpen, setIsOpen] = useState(currentParentCommentId === rootCommentId);

  const toggle = useCallback((nextState?: boolean) => {
    setIsOpen((prevIsOpen) => {
      if (nextState !== undefined) {
        return nextState;
      }
      return !prevIsOpen;
    });
  }, []);

  return (
    <ChildrenOpenStateContext.Provider value={isOpen}>
      <ToggleChildrenContext.Provider value={toggle}>{children}</ToggleChildrenContext.Provider>
    </ChildrenOpenStateContext.Provider>
  );
};

const useChildrenOpenState = () => getDefined(useContext(ChildrenOpenStateContext));
const useToggleChildren = () => getDefined(useContext(ToggleChildrenContext));

export { ChildrenOpenStateProvider, useChildrenOpenState, useToggleChildren };
