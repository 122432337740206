import { useCallback, RefObject, useEffect } from 'react';
import { useTwitter } from './useTwitter';

export function useLoadTwitter(twitterContainerRef: RefObject<HTMLElement>) {
  const twitter = useTwitter();

  const updateTheme = useCallback(() => {
    const meta = document.querySelector('meta[name="twitter:widgets:theme"]') as HTMLMetaElement | undefined;
    const twitterContainer = twitterContainerRef.current;

    if (meta && twitterContainer) {
      const nextTheme = meta.content;

      const tweets = twitterContainer.querySelectorAll('[data-tweet-id]');

      tweets.forEach((tweet) => {
        const src = tweet.getAttribute('src');
        if (src) {
          const nextSrc = src.replace(/theme=(dark|light)/, `theme=${nextTheme}`);

          if (nextSrc !== src) {
            tweet.setAttribute('src', nextSrc);
          }
        }
      });
    }
  }, [twitterContainerRef]);

  const load = useCallback(() => {
    const twitterContainer = twitterContainerRef.current;

    if (twitterContainer) {
      const tweetElements = twitterContainer.querySelectorAll('.twitter-tweet');

      if (tweetElements.length) {
        tweetElements.forEach((tweetElement) => {
          // eslint-disable-next-line no-param-reassign
          (tweetElement as HTMLElement).dataset.theme = (
            (document.querySelector('meta[name="twitter:widgets:theme"]') as HTMLMetaElement | undefined) || {}
          ).content;
        });
      }

      twitter?.ready(() => {
        void twitter.widgets?.load(twitterContainer).then(() => {
          updateTheme();
        });
      });
    }
  }, [twitter, twitterContainerRef, updateTheme]);

  useEffect(() => {
    const meta = document.querySelector('meta[name="twitter:widgets:theme"]') as HTMLMetaElement | undefined;

    if (meta) {
      const onColorSchemeChange = () => {
        updateTheme();
      };

      const observer = new MutationObserver(onColorSchemeChange);

      observer.observe(meta, { attributes: true, attributeFilter: ['content'] });

      return () => {
        observer.disconnect();
      };
    }

    return () => {};
  }, [updateTheme]);

  useEffect(() => {
    load();
  }, [load, twitterContainerRef]);
}
