import { Recommendation } from '../types';
import { ROUTES } from '../../../lib/ROUTES';
import { Clickable } from '../../../components/Clickable';
import { Grid } from './PostContent';
import { CoverImage, CoverType } from '../../../components/CoverImage';
import { tw } from '../../../styles/tw';
import { layout } from '../../../styles/layout';
import { AdvertisementId, Advertisement } from '../../../components/Advertisement';
import { appendSearchParams } from '../../../lib/appendSearchParams';

interface RecommendationsProps {
  recommendations: Recommendation[];
}

const Recommendations = ({ recommendations }: RecommendationsProps) =>
  recommendations.length ? (
    <Grid className={tw(layout, 'lg:pb-4')}>
      <div className="flex flex-col items-start">
        <Advertisement className="mt-6 hidden lg:flex" id={AdvertisementId.DesktopRecommendationsSideBanner} />
      </div>
      <div>
        <div className="flex flex-col [&>*]:border-t [&>*]:border-blood [&>*]:px-0 [&>*]:py-5.5 [&>:first-child]:border-0 [&>:last-child]:border-b [&>:last-child]:border-blood lg:[&>:last-child]:border-b-0 lg:[&>:last-child]:pb-0">
          {recommendations.map(({ title, slug, searchParams, cover }) => (
            <Clickable
              className="flex items-start justify-start"
              key={slug}
              to={appendSearchParams(ROUTES.POST.buildPath({ slug }), searchParams)}
            >
              {cover ? (
                <CoverImage className="my-1 ml-0 mr-6 shrink-0" cover={cover} type={CoverType.Recommendation} />
              ) : null}
              <p className="self-center">{title}</p>
            </Clickable>
          ))}
        </div>
      </div>
    </Grid>
  ) : null;

export { Recommendations };
