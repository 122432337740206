export const CHARS_IN_IMAGE = 500;
export const CHARS_IN_IFRAME = 250;
export function getContentLengthArray(rootElement: HTMLElement) {
  const children = [...rootElement.children];

  const content: number[] = [];

  children.forEach((element) => {
    let length = 0;

    const pictures = rootElement.querySelectorAll('picture');
    const iframes = rootElement.querySelectorAll('iframe');

    if (pictures.length > 0) {
      length += CHARS_IN_IMAGE * pictures.length;
    }

    if (iframes.length > 0) {
      length += CHARS_IN_IFRAME;
    }

    length += element instanceof HTMLElement ? element.innerText.replace(/\s+/g, ' ').trim().length : 0;

    content.push(length);
  });

  return content;
}
