export function overrideParams(target: string, source: string): string {
  const targetParams = new URLSearchParams(target);
  const sourceParams = new URLSearchParams(source);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of sourceParams.keys()) {
    targetParams.delete(key);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of sourceParams) {
    targetParams.append(key, value);
  }

  return targetParams.toString();
}
