import { isDefined, useScript, ScriptStatus } from '@flame-frontend-utils/commons';
import { useEffect } from 'react';
import { useIsSpecialMobile } from './useIsSpecialMobile';
import { SpecialHtmlRenderer } from './SpecialHtmlRenderer';
import { HtmlProps } from '../types';

const SpecialHtml = ({ body, className }: HtmlProps) => {
  const isSpecialMobile = useIsSpecialMobile();

  useEffect(() => {
    const oldBodyOverflowX = document.body.style.overflowX;

    if (isDefined(isSpecialMobile)) {
      if (!isSpecialMobile) {
        document.body.style.overflowX = 'hidden';
      }
    }

    return () => {
      document.body.style.overflowX = oldBodyOverflowX;
    };
  }, [isSpecialMobile]);

  const jQueryStatus = useScript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js');

  return isDefined(isSpecialMobile) && jQueryStatus === ScriptStatus.Ready ? (
    <SpecialHtmlRenderer
      key={isSpecialMobile ? 'mobile' : 'desktop'}
      isMobile={isSpecialMobile}
      body={body}
      className={className}
    />
  ) : null;
};

export { SpecialHtml };
