import { memo, useState } from 'react';
import { NewCommentForm } from '../../../../../../components/comments';
import { PostComment } from '../../../types';
import { User } from '../../../../../../react-hooks/useLoginInfo';
import { useChildrenOpenState, useToggleChildren } from './ChildrenOpenStateProvider';
import { Comment } from './Comment';
import { usePost } from '../../../PostContext';

interface CommentableCommentProps {
  user: User | undefined;
  comment: PostComment;
  renderingId?: string;
  isChild?: boolean;
}

const CommentableComment = memo(({ user, comment, renderingId, isChild }: CommentableCommentProps) => {
  const [isReplyFormVisible, setIsReplyFormVisible] = useState(false);
  const onCancelNewComment = () => setIsReplyFormVisible(false);
  const toggleReply = () => setIsReplyFormVisible((prev) => !prev);
  const areChildrenVisible = useChildrenOpenState();
  const toggleChildren = useToggleChildren();
  const { slug, id } = usePost();

  return (
    <>
      <Comment
        key={comment.id}
        user={user}
        comment={comment}
        isChildComment={isChild}
        isReplyFormVisible={isReplyFormVisible}
        toggleReply={toggleReply}
        onCancelNewComment={onCancelNewComment}
        toggleChildren={toggleChildren}
        areChildrenVisible={areChildrenVisible}
      />
      {isReplyFormVisible && (
        <NewCommentForm
          className="mb-10 ml-9 mt-4 text-xs md:text-base"
          slug={slug}
          postId={id}
          receiver={comment}
          renderingId={renderingId}
          responseTo={comment.author.nickname ?? 'Анонимный пользователь'}
          onComplete={onCancelNewComment}
        />
      )}
    </>
  );
});

export { CommentableComment };
