import { ReactNode } from 'react';
import { tw } from '../../styles/tw';

interface BadgeProps {
  children?: ReactNode;
  round?: boolean;
  className?: string;
}

const Badge = ({ children, round, className }: BadgeProps) =>
  round ? (
    <span
      className={tw('mr-1.5 inline-flex h-6 w-6 justify-around rounded-full bg-blood text-sm text-cream', className)}
    >
      {children}
    </span>
  ) : (
    <span className={tw('mr-1.5 inline-block rounded-full bg-blood px-2 py-0 text-sm text-cream', className)}>
      {children}
    </span>
  );

export { Badge };
