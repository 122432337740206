import { useScript } from './useScript';

declare global {
  interface Twitter {
    ready(fn: () => unknown): void;
    _e: (() => unknown)[];
    /** This part arrives later */
    widgets?: {
      load(element?: HTMLElement): Promise<void>;
    };
  }

  interface Window {
    twttr?: Twitter;
  }
}
/** @see https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites */
export function useTwitter(): typeof window.twttr {
  let twttr: typeof window.twttr;

  if (typeof window !== 'undefined') {
    window.twttr = window.twttr || {
      ready(fn: () => unknown) {
        if (window.twttr) {
          // eslint-disable-next-line no-underscore-dangle
          window.twttr._e.push(fn);
        }
      },
      _e: [],
    };

    twttr = window.twttr;
  }

  useScript('https://platform.twitter.com/widgets.js', { id: 'twitter-wjs' });

  return twttr;
}
