import { MediaPost } from '../../types';
import { PostBodyType } from '../../../../lib/graphql.document';
import { SpecialPostHeader } from './SpecialPostHeader';
import { PostHeader } from './PostHeader';

interface HeaderProps {
  mediaPost: MediaPost;
  className?: string;
}

export const Header = ({ mediaPost, className }: HeaderProps) =>
  [PostBodyType.Legacy, PostBodyType.Verstka].includes(mediaPost.bodyType) ? (
    <SpecialPostHeader className={className} mediaPost={mediaPost} />
  ) : (
    <PostHeader className={className} mediaPost={mediaPost} />
  );
