export function getInjectionIndicesArray(initialArray: number[], repeat: number, sum: number) {
  const indices = [];

  let currentSum = 0;
  let prevInjectionIndex = 0;

  if (repeat <= 0) {
    return [];
  }

  for (let i = 0; i < initialArray.length; i += 1) {
    if (repeat === i || (currentSum >= sum && i - prevInjectionIndex >= repeat)) {
      indices.push(i - 1);
      currentSum = 0;
      prevInjectionIndex = i;
    }
    currentSum += initialArray[i] ?? 0;
  }

  if (currentSum >= sum && initialArray.length - prevInjectionIndex >= repeat) {
    indices.push(initialArray.length - 1);
  }

  return indices;
}
