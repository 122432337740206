import { useScript, ScriptStatus } from './useScript';

declare global {
  interface Instagram {
    Embeds: {
      process(): void;
    };
  }

  interface Window {
    instgrm?: Instagram;
  }
}
/** @see https://developers.facebook.com/docs/instagram/oembed/?locale=en_US */
export function useInstagram(): typeof window.instgrm {
  const status = useScript('https://www.instagram.com/embed.js');

  return status === ScriptStatus.Ready ? window.instgrm : undefined;
}
