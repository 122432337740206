/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FullMediaPostFragmentFragmentDoc } from '../../../../../gql/FullMediaPostFragment.document';
export type PublishPostMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PublishPostMutation = { __typename?: 'Mutation', publishMediaPost: { __typename?: 'MediaPost', id: string, slug: string, searchParams: string, publishAt: number | string, title: string, subtitle: string, description: string, body?: string | null, species: Types.PostSpecies, bodyType: Types.PostBodyType, isPreview: boolean, commentsCount: number, viewsCount: number, favorite: boolean, status: Types.PostStatus, isAdvertisementEnabled: boolean, disabledAdvertisements: Array<string>, isCoverEnabled: boolean, areCommentsEnabled: boolean, isSpecialProject: boolean, revenue: { __typename?: 'PostRevenue', totalAmount?: string | null, rtbAmount?: string | null, directSalesAmount?: string | null, specialProjectAmount?: string | null, totalTonAmount: string }, createdBy: { __typename?: 'User', id: string, nickname?: string | null }, authors: Array<{ __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }>, tags: Array<{ __typename?: 'Tag', id: string, value: string, alias: string }>, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null, reactions: { __typename?: 'Reactions', likesCount: number, dislikesCount: number, myReaction: Types.Reaction }, recommendations: Array<{ __typename?: 'MediaPost', id: string, title: string, slug: string, searchParams: string, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null }> } };


export const PublishPostDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PublishPost"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"publishMediaPost"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"stream"},"value":{"kind":"EnumValue","value":"BLOG"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FullMediaPostFragment"}}]}}]}},...FullMediaPostFragmentFragmentDoc.definitions]} as unknown as DocumentNode<PublishPostMutation, PublishPostMutationVariables>;