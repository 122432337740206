import { PostStatus, PostSpecies } from '../../../../lib/graphql.document';
import { Meta } from './Meta';
import { MediaPost } from '../../types';
import { BlogBadge } from '../../../../components/BlogBadge';
import { PaidBadge } from '../../../../components/PaidBadge';
import { tw } from '../../../../styles/tw';
import { ModerationBadge } from '../../../../components/ModerationBadge';
import { HIDDEN_PAID_POSTS } from '../../../../lib/HIDDEN_PAID_POSTS';

interface DescriptionProps {
  mediaPost: MediaPost;
  centerOnLg?: boolean;
  className?: string;
}

const Description = ({ mediaPost, centerOnLg, className }: DescriptionProps) => {
  const { species, title, subtitle, isSpecialProject, status } = mediaPost;

  return (
    <div className={tw('lg:flex lg:flex-col lg:justify-between', centerOnLg && 'lg:text-center', className)}>
      <div className={centerOnLg ? 'mb-2.5' : 'mb-5'}>
        <div className={tw('mb-2.5 flex md:mb-4', centerOnLg && 'md:mb-2.5')}>
          <PaidBadge isPaid={isSpecialProject && !HIDDEN_PAID_POSTS.includes(mediaPost.id)} />
          <BlogBadge isBlog={species === PostSpecies.Blog} />
          <ModerationBadge isOnModeration={status === PostStatus.Moderation} />
        </div>
        <h1 className={tw('text-xl', centerOnLg && 'm-auto max-w-screen-lg text-2xl')}>{title}</h1>
        {subtitle ? <p className="mt-3 text-text-secondary">{subtitle}</p> : null}
      </div>
      <Meta mediaPost={mediaPost} removeBorder={centerOnLg} />
    </div>
  );
};

export { Description };
