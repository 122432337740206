import { forwardRef } from 'react';
import { MediaPost } from '../types';
import { Grid, PostContent } from './PostContent';
import { CommentsSection } from './CommentsSection';
import { TOP_PAGE_SPACING } from '../constants';
import { PostHeader } from './Header';
import { PostBodyType, PostStatus } from '../../../lib/graphql.document';
import { Recommendations } from './Recommendations';
import { PostContextProvider } from './PostContext';
import { tw } from '../../../styles/tw';
import { layout } from '../../../styles/layout';

declare module 'react' {
  interface CSSProperties {
    '--top-page-spacing'?: string;
  }
}

interface ContentProps {
  mediaPost: MediaPost;
  observedByVerstka?: boolean;
}

const VERSTKA_OBSERVE_CLASS_NAME = 'verstka-observe';

const Content = forwardRef<HTMLDivElement, ContentProps>(({ mediaPost, observedByVerstka }, ref) => {
  const forceMobileLayout = [PostBodyType.Legacy, PostBodyType.Verstka].includes(mediaPost.bodyType);

  return (
    <PostContextProvider post={mediaPost}>
      <div
        ref={ref}
        style={{ '--top-page-spacing': `${TOP_PAGE_SPACING / 16}rem` }}
        className={tw('mb-auto w-full pt-[var(--top-page-spacing)]', observedByVerstka && VERSTKA_OBSERVE_CLASS_NAME)}
      >
        <div className={tw(!forceMobileLayout && layout)}>
          <Grid as="article" forceMobileLayout={forceMobileLayout} className="lg:pb-4">
            <PostContent className={tw(!forceMobileLayout && 'lg:[grid-area:right]')} mediaPost={mediaPost} />
            <PostHeader className={tw(!forceMobileLayout && 'lg:[grid-area:left]')} mediaPost={mediaPost} />
          </Grid>
        </div>
        <div className={layout}>
          <div className="border-b border-blood" />
        </div>
        {mediaPost.areCommentsEnabled && mediaPost.status === PostStatus.Published ? (
          <CommentsSection slug={mediaPost.slug} commentsCount={mediaPost.commentsCount} />
        ) : null}
        <Recommendations recommendations={mediaPost.recommendations} />
      </div>
    </PostContextProvider>
  );
});

export { Content };
