import { MediaPost } from '../../types';
import { Advertisement, AdvertisementId } from '../../../../components/Advertisement';
import { TOP_PAGE_SPACING } from '../../constants';
import { Description } from './Description';
import { tw } from '../../../../styles/tw';

declare module 'react' {
  interface CSSProperties {
    '--top-page-spacing'?: string;
    '--advertisement-top-spacing'?: string;
  }
}

interface PostHeaderProps {
  mediaPost: MediaPost;
  className?: string;
}

const ADVERTISEMENT_TOP_SPACING = 25; // px

const PostHeader = ({ mediaPost, className }: PostHeaderProps) => (
  <header
    style={{
      '--top-page-spacing': `${TOP_PAGE_SPACING / 16}rem`,
      '--advertisement-top-spacing': `${ADVERTISEMENT_TOP_SPACING / 16}rem`,
    }}
    className={tw('lg:flex lg:flex-col lg:justify-between', className)}
  >
    <Description mediaPost={mediaPost} />
    <div className="flex grow items-start justify-start">
      <Advertisement
        className="sticky top-[calc(var(--header-height)+var(--advertisement-top-spacing))] mb-6 mt-[var(--advertisement-top-spacing)] hidden lg:flex"
        id={AdvertisementId.DesktopContentSideBanner}
      />
    </div>
  </header>
);

export { PostHeader };
