import { CommentableComment } from './CommentableComment';
import { PostComment } from '../../../types';
import { User } from '../../../../../../react-hooks/useLoginInfo';
import { useChildrenOpenState } from './ChildrenOpenStateProvider';
import { Advertisement, AdvertisementId } from '../../../../../../components/Advertisement';

interface CommentWithChildrenProps {
  comment: PostComment;
  user: User | undefined;
  desktopAdIds: string[];
  mobileAdIds: string[];
}

const CommentWithChildren = ({ user, comment, desktopAdIds, mobileAdIds }: CommentWithChildrenProps) => {
  const { children } = comment;
  const isOpen = useChildrenOpenState();

  return (
    <>
      <CommentableComment user={user} comment={comment} renderingId={comment.id} />
      {isOpen && children?.length
        ? children.map((childComment) => (
            <CommentableComment
              key={childComment.id}
              user={user}
              comment={childComment}
              renderingId={comment.id}
              isChild
            />
          ))
        : null}
      {desktopAdIds.includes(comment.id) ? (
        <div className="-mt-px hidden justify-center border-y border-blood px-0 py-7 md:flex">
          <Advertisement id={AdvertisementId.DesktopInCommentsBanner} />
        </div>
      ) : null}
      {mobileAdIds.includes(comment.id) ? (
        <div className="-mt-px flex justify-center border-y border-blood px-0 py-7 md:hidden">
          <Advertisement id={AdvertisementId.MobileInComments} />
        </div>
      ) : null}
    </>
  );
};

export { CommentWithChildren };
