import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

const TZ = 'Europe/Moscow';

export const utcToAppZonedTime = (date: Parameters<typeof utcToZonedTime>[0]): Date => utcToZonedTime(date, TZ);
export const appZonedTimeToUtc = (date: Parameters<typeof zonedTimeToUtc>[0]): Date => zonedTimeToUtc(date, TZ);

export const formatInAppTimezone = (date: string | number, format: string) =>
  formatInTimeZone(new Date(date), TZ, format, { locale: ru });
