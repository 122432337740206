import { getDefined } from '@flame-frontend-utils/commons';
import { createContext, useContext, ReactNode } from 'react';
import { MediaPost } from '../types';

const PostContext = createContext<MediaPost | undefined>(undefined);

interface PostContextProviderProps {
  post: MediaPost;
  children: ReactNode;
}

const PostContextProvider = ({ post, children }: PostContextProviderProps) => (
  <PostContext.Provider value={post}>{children}</PostContext.Provider>
);

const usePost = () => getDefined(useContext(PostContext));

export { PostContextProvider, usePost };
