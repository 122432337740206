import { useEffect } from 'react';

export function useStyleLock() {
  useEffect(() => {
    const originalHtmlStyle = document.documentElement.getAttribute('style');
    const originalBodyStyle = document.body.getAttribute('style');

    const htmlObserver = new MutationObserver(() => {
      document.documentElement.setAttribute('style', originalHtmlStyle ?? '');
      htmlObserver.takeRecords();
    });

    const bodyObserver = new MutationObserver(() => {
      document.body.setAttribute('style', originalBodyStyle ?? '');
      bodyObserver.takeRecords();
    });

    htmlObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] });
    bodyObserver.observe(document.body, { attributes: true, attributeFilter: ['style'] });

    return () => {
      htmlObserver.disconnect();
      bodyObserver.disconnect();
    };
  }, []);
}
