import { RefObject, useEffect } from 'react';
import { useIsHydratedRef } from '../components';

export function useHtmlScripts(ref: RefObject<HTMLElement>, html: string, always?: boolean) {
  const isHydratedRef = useIsHydratedRef();

  useEffect(() => {
    if (isHydratedRef.current || always) {
      ref.current?.querySelectorAll('script').forEach((node) => {
        const selfScript = document.createElement('script');

        selfScript.innerHTML = node.innerHTML;

        Array.from(node.attributes).forEach((attribute) => {
          selfScript.setAttribute(attribute.nodeName, attribute.nodeValue as string);
        });

        node.parentNode?.replaceChild(selfScript, node);
      });
    }
  }, [html, isHydratedRef, ref, always]);
}
