import { RefObject, useEffect } from 'react';
import { COLOR_SCHEME_DATA_ATTRIBUTE, ColorScheme } from '../components';

export function useLoadTelegram(telegramContainerRef: RefObject<HTMLElement>) {
  useEffect(() => {
    const telegramContainer = telegramContainerRef.current;

    if (telegramContainer) {
      const colorScheme = document.documentElement.getAttribute(COLOR_SCHEME_DATA_ATTRIBUTE);
      const telegramPostWidgets = telegramContainer.querySelectorAll('.telegram-post-widget');

      if (telegramPostWidgets.length) {
        telegramPostWidgets.forEach((telegramPostWidget) => {
          if (telegramPostWidget instanceof HTMLAnchorElement) {
            const postValue = telegramPostWidget.href.match(/[^/]+\/[^/]+$/g)?.[0];

            if (postValue) {
              const script: HTMLScriptElement = document.createElement('script');
              script.async = true;
              script.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?21');
              script.setAttribute('data-telegram-post', postValue);
              script.setAttribute('data-width', '100%');
              script.setAttribute('data-dark', colorScheme === ColorScheme.Dark ? '1' : '0');

              telegramPostWidget.parentNode?.replaceChild(script, telegramPostWidget);
            }
          }
        });
      }
    }
  }, [telegramContainerRef]);

  useEffect(() => {
    const updateTheme = () => {
      const telegramContainer = telegramContainerRef.current;

      if (telegramContainer) {
        const nextTheme = document.documentElement.getAttribute(COLOR_SCHEME_DATA_ATTRIBUTE);

        if (nextTheme) {
          const telegramPosts = telegramContainer.querySelectorAll('[id*=telegram-post]');

          telegramPosts.forEach((telegramPost) => {
            const src = telegramPost.getAttribute('src');
            if (src) {
              const themeParam = `dark=${nextTheme === ColorScheme.Dark ? '1' : '0'}`;
              const nextSrc = src.includes('dark=') ? src.replace(/dark=([01])/, themeParam) : `${src}&${themeParam}`;

              if (nextSrc !== src) {
                telegramPost.setAttribute('src', nextSrc);
              }
            }
          });
        }
      }
    };

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.documentElement, { attributes: true, attributeFilter: [COLOR_SCHEME_DATA_ATTRIBUTE] });

    return () => {
      observer.disconnect();
    };
  }, [telegramContainerRef]);
}

export function getTelegramWidgetEmbedHtml(url: string) {
  const urlWithProtocol = /^https?:\/\//.test(url) ? url : `https://${url}`;

  if (/^https?:\/\/t.me\/[^/]+\/\d+$/.test(urlWithProtocol)) {
    return `<a target="_blank" rel="noopener noreferrer nofollow" class="telegram-post-widget" href="${url}">${url}</a>`;
  }

  throw new Error('Invalid URL');
}
