import { Revenue } from '../../types';

interface RevenueDisplayProps {
  revenue: Revenue;
}

const RevenueDisplay = ({ revenue }: RevenueDisplayProps) => (
  <p className="text-blood">
    Revenue:{'\u00A0'}
    {formatRubles(revenue.totalAmount ?? '0')} (RTB:{'\u00A0'}
    {formatRubles(revenue.rtbAmount ?? '0')}, specials:{'\u00A0'}
    {formatRubles(revenue.specialProjectAmount ?? '0')}, banners:{'\u00A0'}
    {formatRubles(revenue.directSalesAmount ?? '0')})
  </p>
);

function formatRubles(value: string): string {
  return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(Number(value));
}

export { RevenueDisplay };
