import { useEffect, useState } from 'react';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { MIN_WIDTH } from '../../../../../styles/width';

export function useViewport(): string | null {
  const [viewportContent, setViewportContent] = useState<null | string>(null);

  useEffect(() => {
    const targetNode: HTMLMetaElement | null = document.querySelector('meta[name="viewport"]');
    assertIsDefined(targetNode);

    const originalContent = targetNode.content;

    const observer = new MutationObserver(() => {
      if (originalContent) {
        const nextLockedWidth = targetNode.content.replace(/width=\d+/, `width=${MIN_WIDTH}`);

        if (nextLockedWidth) {
          setViewportContent(nextLockedWidth);
        }

        targetNode.content = originalContent;

        observer.takeRecords();
      }
    });

    observer.observe(targetNode, { attributes: true, attributeFilter: ['content'] });

    return () => {
      observer.disconnect();
      targetNode.content = originalContent;
    };
  }, []);

  return viewportContent;
}
