/* eslint-disable */

import { useState, useEffect } from 'react';

function useIsSpecialMobile() {
  const [isMobile, setIsMobile] = useState<boolean | undefined>();

  useEffect(() => {
    const a = {};
    getSpecialDeviceDetector(a);

    // @ts-ignore
    setIsMobile(a.isMobile.phone);
  }, []);

  return isMobile;
}

/** This is copied from specials init script */
// @ts-ignore
function getSpecialDeviceDetector(e) {
  const n = /iPhone/i;
  const t = /iPod/i;
  const r = /iPad/i;
  const a = /\bAndroid(?:.+)Mobile\b/i;
  const p = /Android/i;
  const l = /\bAndroid(?:.+)SD4930UR\b/i;
  const b = /\bAndroid(?:.+)(?:KF[A-Z]{2,4})\b/i;
  const f = /Windows Phone/i;
  const u = /\bWindows(?:.+)ARM\b/i;
  const c = /BlackBerry/i;
  const s = /BB10/i;
  const v = /Opera Mini/i;
  const h = /\b(CriOS|Chrome)(?:.+)Mobile/i;
  const w = /\Mobile(?:.+)Firefox\b/i;

  // @ts-ignore
  function m(e, i) {
    return e.test(i);
  }

  // @ts-ignore
  function i(e) {
    let i = e || (typeof navigator !== 'undefined' ? navigator.userAgent : '');
    let o = i.split('[FBAN');
    void 0 !== o[1] && (i = o[0]), void 0 !== (o = i.split('Twitter'))[1] && (i = o[0]);
    const d = {
      apple: {
        phone: m(n, i) && !m(f, i),
        ipod: m(t, i),
        tablet: !m(n, i) && m(r, i) && !m(f, i),
        device: (m(n, i) || m(t, i) || m(r, i)) && !m(f, i),
      },
      amazon: { phone: m(l, i), tablet: !m(l, i) && m(b, i), device: m(l, i) || m(b, i) },
      android: {
        phone: (!m(f, i) && m(l, i)) || (!m(f, i) && m(a, i)),
        tablet: !m(f, i) && !m(l, i) && !m(a, i) && (m(b, i) || m(p, i)),
        device: !m(f, i) && (m(l, i) || m(b, i) || m(a, i) || m(p, i)),
      },
      windows: { phone: m(f, i), tablet: m(u, i), device: m(f, i) || m(u, i) },
      other: {
        blackberry: m(c, i),
        blackberry10: m(s, i),
        opera: m(v, i),
        firefox: m(w, i),
        chrome: m(h, i),
        device: m(c, i) || m(s, i) || m(v, i) || m(w, i) || m(h, i),
      },
    };
    return (
      // @ts-ignore
      (d.any = d.apple.device || d.android.device || d.windows.device || d.other.device),
      // @ts-ignore
      (d.phone = d.apple.phone || d.android.phone || d.windows.phone),
      // @ts-ignore
      (d.tablet = d.apple.tablet || d.android.tablet || d.windows.tablet),
      d
    );
  }

  // @ts-ignore
  e.isMobile = i();
}

export { useIsSpecialMobile };
