import { Badge } from '../Badge';

interface PaidBadgeProps {
  isPaid?: boolean;
  small?: boolean;
  className?: string;
}

const PaidBadge = ({ isPaid, small, className }: PaidBadgeProps) => {
  if (!isPaid) {
    return null;
  }

  return small ? (
    <Badge round className={className}>
      С
    </Badge>
  ) : (
    <Badge className={className}>Спецпроект</Badge>
  );
};

export { PaidBadge };
