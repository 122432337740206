import { useEffect, useMemo } from 'react';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { PostComment } from '../../../types';
import { AdvertisementId, useRepeats } from '../../../../../../components/Advertisement';
import { ChildrenOpenStateProvider } from './ChildrenOpenStateProvider';
import { CommentWithChildren } from './CommentWithChildren';
import { ROUTES } from '../../../../../../lib/ROUTES';
import { useLoginInfo } from '../../../../../../react-hooks/useLoginInfo';

interface CommentListProps {
  comments: PostComment[] | undefined;
}

const CommentList = ({ comments }: CommentListProps) => {
  const [{ targetCommentId }] = useTypedSearchParams(ROUTES.POST);

  const { user } = useLoginInfo();

  useEffect(() => {
    if (targetCommentId) {
      document.getElementById(targetCommentId)?.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  }, [targetCommentId]);

  const desktopRepeats = useRepeats(AdvertisementId.DesktopInCommentsBanner);
  const mobileRepeats = useRepeats(AdvertisementId.MobileInComments);

  const desktopAdIds = useMemo(() => getAdvertisementIds(comments ?? [], desktopRepeats), [comments, desktopRepeats]);
  const mobileAdIds = useMemo(() => getAdvertisementIds(comments ?? [], mobileRepeats), [comments, mobileRepeats]);

  return (
    <div className="mx-0 mb-6 mt-3.5 flex w-full flex-col items-stretch justify-center">
      {comments?.map((comment) => (
        <ChildrenOpenStateProvider key={comment.id} rootCommentId={comment.id}>
          <CommentWithChildren comment={comment} user={user} desktopAdIds={desktopAdIds} mobileAdIds={mobileAdIds} />
        </ChildrenOpenStateProvider>
      ))}
    </div>
  );
};

function getAdvertisementIds(comments: PostComment[], repeats: number | null): string[] {
  return repeats ? comments.map((comment) => comment.id).filter((_, index) => !((index + 1) % repeats)) : [];
}

export { CommentList };
