import { RefObject } from 'react';
import SortIcon from '../icons/sort.svg?svgr';
import { Button } from '../../../../../components/Button';
import { HEADER_HEIGHT } from '../../../../../styles/fixedSizes';
import { CommentOrderField, OrderDirection } from '../../../../../lib/graphql.document';

interface CommentSectionHeaderProps {
  commentsCount: number;
  anchorRef: RefObject<HTMLDivElement>;
  onSortClick: () => void;
  currentSort: CommentOrderField.CreatedAt | CommentOrderField.Popularity;
  currentDirection: OrderDirection.Asc | OrderDirection.Desc;
}

const CommentSectionHeader = ({
  commentsCount,
  anchorRef,
  currentSort,
  currentDirection,
  onSortClick,
}: CommentSectionHeaderProps) => (
  <>
    <div className="flex items-center justify-between">
      <h2>Комменты {commentsCount}</h2>
      <Button className="gap-2.5" onClick={onSortClick}>
        {currentSort === CommentOrderField.CreatedAt
          ? currentDirection === OrderDirection.Desc
            ? 'Последние'
            : 'По порядку'
          : ''}
        {currentSort === CommentOrderField.Popularity && 'Популярные'}
        <SortIcon />
      </Button>
    </div>
    <div
      style={{ '--header-height': `${HEADER_HEIGHT / 16}rem` }}
      /* eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values */
      className="absolute -top-[var(--header-height)]"
      ref={anchorRef}
    />
  </>
);

export { CommentSectionHeader };
