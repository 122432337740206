import { useLoadTwitter, useLoadTelegram, useLoadInstagram } from '@flame-frontend-utils/commons';
import { useRef } from 'react';
import { useContentAdvertisements } from '../../../useContentAdvertisements';
import { FlameHtmlRenderer } from './FlameHtmlRenderer';
import { HtmlProps } from '../types';
import { useLoadFlameImages } from './useLoadFlameImages';

const FlameHtml = ({ body, disableAds, className }: HtmlProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const advertisements = useContentAdvertisements(contentRef, disableAds);
  const imageControls = useLoadFlameImages(contentRef);
  useLoadTwitter(contentRef);
  useLoadTelegram(contentRef);
  useLoadInstagram();

  return (
    <>
      <FlameHtmlRenderer className={className} body={body} ref={contentRef} />
      {advertisements}
      {imageControls}
    </>
  );
};

export { FlameHtml };
