import { Badge } from '../Badge';

interface BlogBadgeProps {
  isBlog: boolean;
  className?: string;
}

const BlogBadge = ({ isBlog, className }: BlogBadgeProps) => {
  if (!isBlog) {
    return null;
  }

  return <Badge className={className}>Блоги</Badge>;
};

export { BlogBadge };
