import { useMutation } from '@apollo/client';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { AddPostToFavoritesDocument } from '../../gql/AddPostToFavorites.document';
import { RemovePostFromFavoritesDocument } from '../../gql/RemovePostFromFavorites.document';
import { Button } from '../../../../../components/Button';
import Bookmark from './bookmark.svg?svgr';
import ActiveBookmark from './active-bookmark.svg?svgr';
import { useLoginInfo } from '../../../../../react-hooks/useLoginInfo';
import { useOnLoginFormOpen } from '../../../../../components/LoginFormProvider';
import { ignoreReject } from '../../../../../lib/ignoreReject';

interface FavoritesButtonProps {
  postId: string;
  isFavorite: boolean;
}

const FavoritesButton = ({ postId, isFavorite }: FavoritesButtonProps) => {
  const { data: loginData } = useLoginInfo();
  const onLoginFormOpen = useOnLoginFormOpen();

  const [addFavorite, { loading: addLoading }] = useMutation(AddPostToFavoritesDocument);
  const [removeFavorite, { loading: removeLoading }] = useMutation(RemovePostFromFavoritesDocument);

  const onAddFavorite = () => {
    void ignoreReject(addFavorite({ variables: { postId } }));
  };

  const onRemoveFavorite = () => {
    void ignoreReject(removeFavorite({ variables: { postId } }));
  };

  const onClick = () => {
    assertIsDefined(loginData);
    if (loginData.loginInfo.user) {
      if (!isFavorite) {
        onAddFavorite();
      } else {
        onRemoveFavorite();
      }
    } else {
      onLoginFormOpen();
    }
  };

  return loginData ? (
    <Button
      onClick={onClick}
      disabled={addLoading || removeLoading}
      aria-label={isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
    >
      <p className="mr-3">{isFavorite ? 'Убрать' : 'В избранное'}</p>
      {isFavorite ? <ActiveBookmark /> : <Bookmark />}
    </Button>
  ) : null;
};

export { FavoritesButton };
