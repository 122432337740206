import { MediaPost } from '../types';
import { useDynamicSlug } from '../useDynamicSlug';
import { Content } from './Content';

interface PromotedContentProps {
  mediaPost: MediaPost;
  parentPost: MediaPost;
}

const PromotedContent = ({ mediaPost, parentPost }: PromotedContentProps) => {
  const { intersectionObserverRef } = useDynamicSlug(mediaPost, parentPost);

  return <Content ref={intersectionObserverRef} mediaPost={mediaPost} />;
};

export { PromotedContent };
