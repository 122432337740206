import { Helmet } from 'react-helmet-async';
import { getResizedImageUrl } from '@flame-frontend-utils/tiptap';
import { MediaPostQuery } from '../gql/MediaPost.document';
import { getDisplayedName } from '../../../lib/getDisplayedName';
import { PostSpecies } from '../../../lib/graphql.document';
import { ROUTES } from '../../../lib/ROUTES';
import { MAX_DIMENSIONS, CoverType, isCropSettingsMap } from '../../../components/CoverImage';

interface LinkedDataProps {
  post: Exclude<MediaPostQuery['mediaPost'], null | undefined>;
}

const LINKED_DATA_POST_CLASS_NAME = 'linked-data-post';

const LinkedData = ({ post }: LinkedDataProps) => {
  const author = post.authors[0];

  const linkedData = {
    '@context': 'http://schema.org',
    '@type': post.species === PostSpecies.Blog ? 'BlogPosting' : 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST}${ROUTES.POST.buildPath({ slug: post.slug })}`,
    },
    headline: post.title,
    image: post.cover?.originalUrl
      ? getResizedImageUrl({
          resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
          originalUrl: post.cover.originalUrl,
          cropSettings: isCropSettingsMap(post.cover.cropSettings) ? post.cover.cropSettings.horizontal : undefined,
          width: MAX_DIMENSIONS[CoverType.Promo].width,
          height: MAX_DIMENSIONS[CoverType.Promo].height,
          dpr: 2,
        })
      : undefined,
    datePublished: new Date(post.publishAt).toISOString(),
    dateModified: new Date(post.publishAt).toISOString(),
    author: author ? { '@type': 'Person', name: getDisplayedName(author.nickname, author.alias) } : undefined,
    publisher: {
      name: PUBLIC_CONFIG.APP_NAME,
      '@type': 'Organization',
      logo: { '@type': 'ImageObject', url: `${PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST}/favicon.ico` },
    },
    description: post.description,
    isAccessibleForFree: 'True',
    hasPart: [
      { '@type': 'WebPageElement', isAccessibleForFree: 'True', cssSelector: `.${LINKED_DATA_POST_CLASS_NAME}` },
    ],
  };

  return (
    <Helmet
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(linkedData),
        },
      ]}
    />
  );
};

export { LinkedData, LINKED_DATA_POST_CLASS_NAME };
