import { Loader } from '../../components/Loader';
import { Page } from '../../components/Page';

interface LoadingPageProps {
  statusCode?: number;
}
const LoadingPage = ({ statusCode }: LoadingPageProps): JSX.Element => (
  <Page hideAdvertisement statusCode={statusCode}>
    <Loader className="m-auto" />
  </Page>
);

export { LoadingPage };
