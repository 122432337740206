import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { everyGqlErrorIs } from '@flame-frontend-utils/commons-apollo';
import { useMutation } from '@apollo/client';
import { ROUTES } from '../../lib/ROUTES';
import { ViewPostBySlugDocument } from './gql/ViewPostBySlug.document';
import { GqlError } from '../../lib/graphql.document';
import { useSetCustomTitle } from '../../components/Page';
import { FullMediaPostFragmentFragment } from '../../gql/FullMediaPostFragment.document';
import { ignoreReject } from '../../lib/ignoreReject';
import { sendPageViewToMetrika } from '../../components/MetrikaPageViewReporter';
import { appendSearchParams } from '../../lib/appendSearchParams';
import { overrideParams } from '../../lib/overrideParams';

const READ_WAIT = 10000; // ms

export const useDynamicSlug = (
  promotedPost: FullMediaPostFragmentFragment,
  parentPost: FullMediaPostFragmentFragment
) => {
  const [isStatsSent, setIsStatsSent] = useState(false);
  const [viewPost] = useMutation(ViewPostBySlugDocument, {
    context: { disableErrorPopup: everyGqlErrorIs(GqlError.PostNotFound) },
  });

  const timeoutIdPromoPost = useRef<ReturnType<typeof setTimeout>>();

  const { ref: intersectionObserverRef, inView, entry } = useInView({ delay: 100 });

  const setCustomTitle = useSetCustomTitle();

  useEffect(() => {
    const isPromotedPostAbove = entry && entry.boundingClientRect.top < 0;

    if (inView) {
      window.history.replaceState(
        null,
        '',
        appendSearchParams(
          ROUTES.POST.buildPath({ slug: promotedPost.slug }),
          overrideParams(window.location.search, promotedPost.searchParams)
        )
      );
      sendPageViewToMetrika();
      setCustomTitle(promotedPost.title);

      if (!isStatsSent) {
        timeoutIdPromoPost.current = setTimeout(() => {
          void ignoreReject(viewPost({ variables: { slug: promotedPost.slug, referrerSlug: parentPost.slug } }));
          setIsStatsSent(true);
        }, READ_WAIT);
      }
    }

    if (!inView && !isPromotedPostAbove && parentPost.slug) {
      window.history.replaceState(
        '',
        '',
        appendSearchParams(
          ROUTES.POST.buildPath({ slug: parentPost.slug }),
          overrideParams(window.location.search, parentPost.searchParams)
        )
      );
      sendPageViewToMetrika();
      setCustomTitle(null);
    }

    return () => {
      if (timeoutIdPromoPost.current) {
        clearTimeout(timeoutIdPromoPost.current);
      }
    };
  }, [parentPost, entry, inView, promotedPost, viewPost, isStatsSent, setCustomTitle]);

  return { intersectionObserverRef };
};
