import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DeletePostDocument } from './gql/DeletePost.document';
import { PublishPostDocument } from './gql/PublishPost.document';
import { UnpublishPostDocument } from './gql/UnpublishPost.document';
import { ROUTES } from '../../../../lib/ROUTES';
import { RequestPostModerationDocument } from './gql/RequestPostModeration.document';
import { ignoreReject } from '../../../../lib/ignoreReject';

export const useActionsButtons = (postId: string) => {
  const navigate = useNavigate();

  const mutationOptions = {
    variables: { id: postId },
  };

  const [deletePost, { loading: deleting }] = useMutation(DeletePostDocument, mutationOptions);
  const [publishPost, { loading: publishing }] = useMutation(PublishPostDocument, mutationOptions);
  const [unpublishPost, { loading: unpublishing }] = useMutation(UnpublishPostDocument, mutationOptions);
  const [requestPostModeration, { loading: requestingModeration }] = useMutation(
    RequestPostModerationDocument,
    mutationOptions
  );

  const onDelete = () => {
    void ignoreReject(
      deletePost().then(() => {
        navigate(ROUTES.BLOGS.path);
      })
    );
  };
  const onPublish = () => ignoreReject(publishPost());
  const onUnpublish = () => ignoreReject(unpublishPost());

  const onRequestModeration = () => ignoreReject(requestPostModeration());

  return {
    onDelete,
    onPublish,
    onUnpublish,
    onRequestModeration,
    deleting,
    publishing,
    unpublishing,
    requestingModeration,
    loading: deleting || publishing || unpublishing || requestingModeration,
  };
};
