import { useEffect } from 'react';
import { useInstagram } from './useInstagram';

export function useLoadInstagram() {
  const instagram = useInstagram();

  useEffect(() => {
    if (instagram) {
      instagram.Embeds.process();
    }
  }, [instagram]);
}
